import "./footer.css";
import { FaReact, FaGithub, FaLinkedin } from "react-icons/fa";
import { GiBleedingEye } from "react-icons/gi";

const Footer = () => {

  const date = new Date();
  const copyDate = date.getFullYear();

  return (
    <>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-card">
            <h3 className="footer-title">Let's Jam together!</h3>
            <ul className="social">
              <li className="social-items">
                <a href="https://github.com/mayhemds" target="blank"><FaGithub /></a>
              </li>
              <li className="social-items">
                <a href="https://linkedIn.com/in/tyronemay" target="blank"><FaLinkedin /></a>
              </li>
            </ul>
          </div>
        </div>
        <p className="footer-copy">
          Built on React{" "}
          <FaReact
            style={{
              marginBottom: "-5px",
              color: "lightBlue",
              fontSize: "1.2rem",
            }}
          />{" "}
          Designed with a creative{" "}
          <GiBleedingEye
            style={{ marginBottom: "-5px", color: "red", fontSize: "1.2rem" }}
          />{" "}
          Home to Tyrone May &copy; {copyDate}
        </p>
      </div>
    </>
  );
};

export default Footer;
