import TimeLine from '../../components/TimeLine';
import Skills from '../../components/Skills';
import './cv.css';

const CV = () => {
    return ( 
        <>
        <div className="cv-section" id="cv">
            <div className="cv-container">
            <h2 className="cv-heading"><span className="topLine"></span>Curriculum Vitae</h2>
                <div className="cv-wrap">
                    <TimeLine/>
                    <Skills />
                </div>
            </div>
        </div>
        </>
     );
}
 
export default CV;