import { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import "./navbar.css";

const Navbar = () => {
  /*  create useState for the navigation toggle */
  const [click, SetClick] = useState(false);
  /* set the handleClick to between on and off state */
  const handleClick = () => SetClick(!click);
  /* set closeMobileMenu to false to close the mobile window onClick */
  const closeMobileMenu = () => SetClick(false);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={toggleHome}>
            <img className="logo" src="/images/herologo.png" alt="Tyrone May" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="about"
                activeClass="active"
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                duration={500}
                exact="true"
                offset={-80}
              >
                About
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="projects"
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                duration={500}
                exact="true"
                offset={-80}
              >
                Projects
              </Link>
            </li>
            <li className="nav-item">
            <Link
                to="cv"
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                duration={500}
                exact="true"
                offset={-80}
              >
                Curriculum Vitae
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="testimonials"
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                duration={500}
                exact="true"
                offset={-80}
              >
                Testimonials
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="contact"
                onClick={closeMobileMenu}
                spy={true}
                smooth={true}
                duration={500}
                exact="true"
                offset={-80}
              >
                Contact
              </Link>
            </li>

          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
