import { projects } from "./projects";
import "./projects.css";
const Projects = () => {



  return (
    <>
      <div className="project-section" id="projects">
        <div className="project-container">
        <h2 className="project-heading"><span className="topLine-white"></span>Projects </h2>
            <p className="project-lead">Here is a selection of recent projects I have worked on. <span className="project-message"> Note:</span> Most if not all businesses I contract for require me to sign an NDA, so I cannot provide the source code. The information in the descriptions should suffice along with the links to their websites.</p>
            <p className="project-lead">More information about the project can be seen in my Curriculum Vitae (CV).</p>
          <div className="card-wrap">
            {projects.map(
              ({ id, image, title, desc, tags, source, visit, alt }) => (
                <div className="project-card" key={id}>
                  <img className="project-image" src={image} alt={alt} />
                  <h3 className="title">{title}</h3>
                  <p className="desc">{desc}</p>
                  <div className="stack">
                    <h4 className="stack-title">Stack</h4>
                    <ul className="tag-list">
                      {tags.map((tag, index) => (
                        <li className="tag-list-item" key={index}>
                         {tag}
                        </li>
                      ))}
                    </ul>

                    <div className="btn-wrap">
                      <a className="btn" href={source}>
                        Code
                      </a>
                      <a className="btn" href={visit}>
                        Website
                      </a>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
