
export const projects = [
  {
    id: "0",
    image: ' /images/telecomplus.png',
    alt: "TelecomPlus website project",
    title: "TelecomPlus",
    desc: "Built the TelecomPlus website on the Apostrophe CMS, using the tech stack listed below.",
    tags: ["HTML", "JavaScript", "SCSS", "Apostrophe CMS", "CLI", "Git"],
    source: "https://telecomplus.co.uk",
    visit: "https://telecomplus.co.uk",
  },
  {
    id: "1",
    image: "/images/uw.png",
    alt: "Utility Warehouse website project",
    title: "Utility Warehouse",
    desc: "Contracted to work on the new UW website, creating components and building the site ready for express day",
    tags: ["HTML", "JavaScript", "SCSS", "Nunjucks", "Apostrophe CMS","Git", "CLI"],
    source: "https://uw.co.uk",
    visit: "https://uw.co.uk",
  },
  {
    id: "2",
    image: "/images/artfinder.png",
    alt: "Artfinder e-commerce project",
    title: "Artfinder",
    desc: "Working with other developers to build out the artfinder e-commerce platform.",
    tags: ["HTML",  "SCSS", "Jinja", "Django","Python", "JavaScript", "CLI", "Git"],
    source: "https://artfinder.com",
    visit: "https://artfinder.com",
  },
];
