import './testimonials.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    // 1024: { items: 3 },
};


const items = [
    <div className="item" data-value="1">
        <div className="testcard">
            <img src="/images/artfinder-logo.png"  alt="ArtFinder" className="icon" />
            <h3 className="title">Artfinder - CEO</h3>
            <p className="comment">Tyrone's been a true pleasure to work with at Artfinder! He really likes to get under the skin of complex problems. He got to understand our entire stack in a short period of time and was adding his front-end expertise to many different projects shortly after joining. What I enjoyed most about working with Tyrone was his honesty and openness, but also that he's always eager to learn whatever the area or discipline. He is always willing to contribute ideas and comments to various discussions (whether front-end, broad-technical or business). I would certainly recommend Tyrone to anyone looking not just for his talent, but also for a super-positive person that he is.</p>
            </div>

    </div>,
    <div className="item" data-value="2">
        <div className="testcard">
            <img src="/images/uw-loog.png" alt="Utility Warehouse" className="icon" />
            <h3 className="title">Utility Warehouse - Head of Engineering</h3>
            <p className="comment">I enjoyed working with Tyrone. I found him to be pragmatic and committed to getting things done. He's also a great team player, keen to learn new ideas and techniques, always willing to help out others, share his knowledge and share a laugh. Tyrone would be a great asset to any development team working on delivering customer facing functionality and I hope to get to work with him again.</p>
            </div>

    </div>,
    <div className="item" data-value="3">
          <div className="testcard">
            <img src="/images/FD-logo.png" className="icon" alt="ArtFinder" />
            <h3 className="title">Frontend Developer - Italo Mandara</h3>
            <p className="comment">I’m really glad I had the opportunity to work with Tyrone in the front end team at Artfinder, while in our team his performance was exemplary, being able to finish each task way before the deadline. Tyrone is a real quick learner, even though our project is very complex he got to work independently on his tasks in a short period of time. Apart from his technical skills, Tyrone has also very good design and UX skills. </p>
            </div>
    </div>
];
const Testimonials = () => {
    return ( 
        <>
           <div className="testimonials-section" id="testimonials">
            <div className="testimonials-container">
              
                <h2 className="testimonial-heading"><span className="topLine"></span>Testimonials </h2>
                <p className="lead-text-white">A few words from others that have worked with me - <small> Source LinkedIn Recommendations</small></p>
                <AliceCarousel 
                mouseTracking items={items}
                responsive={responsive} 
                autoPlay="true"
                infinite
                autoPlayInterval={5000}
                disableDotsControls="false"
                />
            </div>
        </div>
        </>
     );
}
 
export default Testimonials;