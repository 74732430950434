import video from '../../Videos/video.mp4';
import './hero.css';
import { Link } from 'react-scroll';
import { FaChevronDown } from 'react-icons/fa';
import '../../components/button.css';
const Hero = () => {

    return ( 
        <div className="hero-container" id="home">
        <video playsInline autoPlay loop muted className="hero-bg" >
          <source className="video-bg" src={video} type="video/mp4" />
        </video>
        <div className="hero-content">
          <div className="hero-header">Hi There, I'm <span className="hero-name">Tyrone May</span>, a Hybrid Frontend Developer</div>
          <div className="hero-text">Dig a little deeper into my World! </div>
          <div className="hero-button-wrapper">
            <div className="hero-button">
               <Link  to="about" spy={true} smooth={true} duration={500} exact='true' offset={-80} primary="true">
             <FaChevronDown  className="hero-arrow"/>
            </Link>
            </div>
          </div>
        </div>
      </div>
     );
}
 
export default Hero;
