export const timeLine = [
  {
    id: "job0",
    title: "Digital UX Developer/Manager - QA",
    date: "Sept 2021 – Present", 
    description: `Overall responsibility for the maintenance and direction of the website. Liaising with third-party organisations that integrate with the website. Designing and delivering an effective digital experience to drive partners and user engagement. Create and design wireframes and mock-ups with the design team to maintain the look and feel of the website. Collaborate with cross-functional QA Teams to check the quality of digital content being added to the website (PDF's correctly formatted, Images and videos compressed and created for all digital devices). Provide A/B multivariant testing for optimising CTA's, landing pages and lead generation forms. Design and deliver new components and functionality based on user experience and feedback. Where needed, code, build and integrate functionality into the website. Provide training to other members of the Digital Marketing Team on how to use the CMS. Manage user access and rights on the CMS. Review third party logos and digital content, following brand guidelines, making sure they pass QA approval before being added to the website. Using my experience and expertise to provide ongoing suggestions and website recommendations.`,
  },
  {
    id: "job1",
    title: "Utility Warehouse - Contract Frontend Developer",
    date: "Jan 2020 – Apr 2021",
    description: `Contracting as a Web Designer and Frontend Developer for agencies, small businesses and individuals.
    Building websites, using HTML, CSS, jQuery, Javascript Frontend frameworks.
    Focusing on page speed and loading times using Googles Page Speed. Building websites using the mobile first approach and responsive design. Working with pre-processors and other web technologies to set up workflows.`,
  },
  {
    id: "job2",
    title: "Saga Health- Contract Frontend Developer",
    date: "Oct 2019 – Dec 2019",
    description: `Contracting as a Web Designer and Frontend Developer on small project that lasted 6 weeks for Saga Health.
    Building a e-commerce website, using HTML, CSS, JavaScript and Umbraco CMS.
    Using the mobile first approach and responsive design. Working with pre-processors and other web technologies to set up workflows.
    `,
  },
  {
    id: "job3",
    title: "Live Holidays - Contract Frontend Developer",
    date: "Sept 2017 – Feb 2018",
    description: `Designing and building the overall structure of the new Cuba website, using WireFrames and mock-ups.
    Creating the designs in adobe PhotoShop and then coding the PSD files in to HTML, CSS and JavaScript templates that would be used in the Umbraco CMS.
    Running the project meetings and covering all aspects of the user journey and experience.
    Working in an agile environment and using Git to push the projects to the backend development team in Cuba.`,
  },
  {
    id: "job4",
    title: "ArtFinder- Contract Frontend Developer",
    date: "Apr 2017 - Jul 2017",
    description: `
    Working for ArtFinder a startup art company as a frontend developer.
    Working with the backend dev team to create new features for the ArtFinder website, coding pages within Django templates, working in an Docker environment and using Slack to keep the team updated with completed PR Tickets.
    Working in an agile development environment with weekly sprints using Jira to keep track of worked on and completed PR tickets
    Working with the designer and coding Ui's features that would be added to the site.
    Running multiple A/B tests to see what UX and UI preforms better`,
  },
  {
    id: "job5",
    title: "e92plus - Frontend Developer",
    date: "Mar 2015 - Mar 2017",
    description: `Solely responsible for designing and coding e92plus company website and focusing on how users interact with the website. Built Magento Stores font-end templates for clients and styled them based around the business brand.
    Building responsive HTML email newsletters for the marketing team to use in their campaigns.
    Working with security vendors to create web, digital and print content for exhibitions and events, such as IP EXPO and INFOSEC.
    `,
  },
];
