import React from "react";
import {timeLine} from './Data';
import './timeline.css';
import {FaStar} from 'react-icons/fa';


const TimeLine = () => {
  return (
    <>
    <div className="timeline-container">
      {timeLine.map(
        ({id, title, date, description}) =>( 
          <div className="timeline-wrap"key={id}>
             <FaStar className="job-bullet"/>
            <div className="job-container" >
             
              <div className="job-section" id={id}> 
             
                <h3 className="job-title">{title}</h3>
                <p className="job-date">{date}</p>
                <p className="job-description">{description}</p>
              </div>
            </div>
          </div>


      ))}
     
    </div>
    </>
  );
};

export default TimeLine;
