import "./contact.css";
import "../button.css";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


const MySwal = withReactContent(Swal);



const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_j8bld83",
        "template_24i7jo9",
        e.target,
        "user_yfofpnnCfLzK1QLoPmroU"
      )
      .then(
        (result) => {
           MySwal.fire("Boom!", "Your form has been submitted!");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
   
  }

  return (
    <>
      <div className="contact-section" id="contact">
        <div className="contact-container">
          <h1 className="contact-heading">
            <span className="topLine"></span>Contact
          </h1>
          <p className="contact-lead">
            Want to talk about a project or position, then drop me a quick
            message and I'll get back to you and we can talk about the
            requirements.
          </p>
          <div className="contact-wrap">
            <form className="contact-form" onSubmit={sendEmail}>
            

              <input type="hidden" name="contact_number" />

              <label className="form-label">Name</label>
              <input
                className="form-fields"
                type="text"
                placeholder="Name"
                name="user_name"
                required
              />
              <label className="form-label">Email</label>
              <input
                className="form-fields"
                type="email"
                placeholder="Email Address"
                name="user_email"
                required
              />
              <label className="form-label">Subject</label>
              <input
                className="form-fields"
                type="text"
                placeholder="Enter a Subject"
                name="subject"
                required
              />
              <label className="form-label">Message</label>
              <textarea
                className="form-textarea"
                placeholder="Your Message"
                name="message"
                required
              /> 
              <div className="g-recaptcha" data-sitekey="6LeKeYYbAAAAANRKULjoNxNxB1QuaKvpNxf3hfG7" required></div>
              <input className="form-btn" type="submit" value="Send" />
             
            </form>
            <div className="mayhem">
              <img  src="/images/mayhemds.png" alt="mayhem" />
            </div>
            
        </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
