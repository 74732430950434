import "./about.css";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { Link } from "react-scroll";
const About = () => {
  return (
    <>
      <div className="about-section" id="about">
        <div className="about-container">
          <h2 className="about-heading">
            <span className="topLine"></span>About Me
          </h2>
          <div className="notice-section">
            <h2>Time for change</h2>
            <p className="lead-text">
              After 3 years, this site is in need of an update to something
              bigger and better. A site that allows me to engage more with you,
              the viewers, that's why I'm building a whole new site from
              scratch, so this site will not longer be updated. Please bare with
              me while I make those changes.
            </p>
          </div>
          <div className="about-wrap">
            <div className="intro-about">
              <p className="lead-text">
                I'm a self-taught Hybrid Designer / Frontend Developer who has
                gained a considerable amount of knowledge in both UX/UI design
                and frontend development. I have the ability to work within a
                team environment or using my own initiative. I analyse problems,
                formulate solutions and high attention to detail.
              </p>
            </div>
            <div className="contract-day-rate">
              <div className="service-band">Closed to opportunities</div>
              <h3 className="contract-heading">
                <span>Currently Permanent</span>
              </h3>
              <p className="contract-text">
                At the moment I'm working in a permanent position, this notice
                section will change if my situation changes.
              </p>
            </div>
          </div>
          <p className="lead-text">
            At a Quick glance, these are some of the tools I use to get the job
            done.
          </p>
          <div className="skills-wrap">
            <ul className="skills">
              <h3> Design</h3>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" />{" "}
                Wire-framing / Mockups
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> Figma
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> Adobe
                Creative Cloud
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> Responsive
                design
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> Sketch
              </li>
            </ul>
            <ul className="skills">
              <h3>Development</h3>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> HTML
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> CSS &amp;
                Pre-processors (Less, SCSS)
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> JavaScript
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> React
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> CMS
                Development
              </li>
            </ul>
            <ul className="skills">
              <h3>Other</h3>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> Git
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> CLI
                (Command Line Interface)
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> Mac,
                Linux, Windows
              </li>
              <li className="skill">
                <IoMdCheckmarkCircleOutline className="skill-icon" /> Agile
                Development
              </li>
            </ul>
          </div>
          <p className="lead-text">
            For a more in depth overview check out my CV
          </p>
          <Link
            activeClass="active"
            spy={true}
            smooth={true}
            duration={500}
            exact="true"
            offset={-80}
            to="cv"
            className="btn"
          >
            View CV
          </Link>
        </div>
      </div>
    </>
  );
};

export default About;
