
import Hero from '../components/Hero';
import About from '../components/About';
import Projects from '../components/Projects';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import CV from '../components/CV';



const Home = () => {
    return ( 
        <>
      <Hero />
      <About /> 
      <Projects />
      <CV />
      <Testimonials />
      <Contact />
     
        </>
     );
}
 
export default Home;