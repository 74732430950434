
import  "../pills.css";
import './skills.css';

const Skills = () => {
  return (
    <div className="skills-container">
      <div className="skills-title" >Frontend Tools &amp; SkillSet</div>
      < ul className="skills-card">
        <li className="pills">HTML</li>
        <li className="pills">SCSS &amp; LESS</li>
        <li className="pills">CSS</li>
         <li className="pills">JavaScript</li>
        <li className="pills">Bootstrap</li>
        <li className="pills">Foundation</li>
        <li className="pills">React</li>
        <li className="pills">Agile Development</li>
        <li className="pills">Git</li>
         <li className="pills">Jira</li>
        <li className="pills">CLI</li>
        <li className="pills">Mobile First</li>

      </ul>
      <div className="skills-title" >Ui Tools</div>
      <ul className="skills-card">
        <li className="pills">PhotoShop</li>
        <li className="pills">Figma</li>
        <li className="pills">Illustrator</li>
        <li className="pills">WireFraming</li>
      </ul>
      <div className="skills-title">OS'es</div>
      <ul className="skills-card">
        <li className="pills">Linux</li>
        <li className="pills">Windows</li>
        <li className="pills">Mac</li>
      </ul>
      <div className="skills-title">CMS'es</div>
      <ul className="skills-card">
        <li className="pills">WordPress</li>
        <li className="pills">Django</li>
        <li className="pills">Umbraco</li>
        <li className="pills">SiteFinity</li>
        <li className="pills">Apostrophe</li>
      </ul>
      <div className="skills-title">Exposure to...</div>
      <ul className="skills-card">
        <li className="pills">Google Analytics</li>
        <li className="pills">Mangento</li>
        <li className="pills">Umbraco</li>
        <li className="pills">Affinity Designer</li>
        <li className="pills">Affinity Photo</li>
        <li className="pills">Python</li>
        <li className="pills">Vue</li>
        <li className="pills">Tailwind css</li>
        <li className="pills">Bulma</li>
      </ul>
    </div>
    
  );
};

export default Skills;
